import _events from "events";
import _url from "url";
import _normalizeUrl from "normalize-url";
import _getStream from "get-stream";
import _httpCacheSemantics from "http-cache-semantics";
import _responselike from "responselike";
import _lowercaseKeys from "lowercase-keys";
import _cloneResponse from "clone-response";
import _keyv from "keyv";
var exports = {};
const EventEmitter = _events;
const urlLib = _url;
const normalizeUrl = _normalizeUrl;
const getStream = _getStream;
const CachePolicy = _httpCacheSemantics;
const Response = _responselike;
const lowercaseKeys = _lowercaseKeys;
const cloneResponse = _cloneResponse;
const Keyv = _keyv;

class CacheableRequest {
  constructor(request, cacheAdapter) {
    if (typeof request !== "function") {
      throw new TypeError("Parameter `request` must be a function");
    }

    this.cache = new Keyv({
      uri: typeof cacheAdapter === "string" && cacheAdapter,
      store: typeof cacheAdapter !== "string" && cacheAdapter,
      namespace: "cacheable-request"
    });
    return this.createCacheableRequest(request);
  }

  createCacheableRequest(request) {
    return (opts, cb) => {
      let url;

      if (typeof opts === "string") {
        url = normalizeUrlObject(urlLib.parse(opts));
        opts = {};
      } else if (opts instanceof urlLib.URL) {
        url = normalizeUrlObject(urlLib.parse(opts.toString()));
        opts = {};
      } else {
        const [pathname, ...searchParts] = (opts.path || "").split("?");
        const search = searchParts.length > 0 ? `?${searchParts.join("?")}` : "";
        url = normalizeUrlObject({ ...opts,
          pathname,
          search
        });
      }

      opts = {
        headers: {},
        method: "GET",
        cache: true,
        strictTtl: false,
        automaticFailover: false,
        ...opts,
        ...urlObjectToRequestOptions(url)
      };
      opts.headers = lowercaseKeys(opts.headers);
      const ee = new EventEmitter();
      const normalizedUrlString = normalizeUrl(urlLib.format(url), {
        stripWWW: false,
        removeTrailingSlash: false,
        stripAuthentication: false
      });
      const key = `${opts.method}:${normalizedUrlString}`;
      let revalidate = false;
      let madeRequest = false;

      const makeRequest = opts => {
        madeRequest = true;
        let requestErrored = false;
        let requestErrorCallback;
        const requestErrorPromise = new Promise(resolve => {
          requestErrorCallback = () => {
            if (!requestErrored) {
              requestErrored = true;
              resolve();
            }
          };
        });

        const handler = response => {
          if (revalidate && !opts.forceRefresh) {
            response.status = response.statusCode;
            const revalidatedPolicy = CachePolicy.fromObject(revalidate.cachePolicy).revalidatedPolicy(opts, response);

            if (!revalidatedPolicy.modified) {
              const headers = revalidatedPolicy.policy.responseHeaders();
              response = new Response(revalidate.statusCode, headers, revalidate.body, revalidate.url);
              response.cachePolicy = revalidatedPolicy.policy;
              response.fromCache = true;
            }
          }

          if (!response.fromCache) {
            response.cachePolicy = new CachePolicy(opts, response, opts);
            response.fromCache = false;
          }

          let clonedResponse;

          if (opts.cache && response.cachePolicy.storable()) {
            clonedResponse = cloneResponse(response);

            (async () => {
              try {
                const bodyPromise = getStream.buffer(response);
                await Promise.race([requestErrorPromise, new Promise(resolve => response.once("end", resolve))]);

                if (requestErrored) {
                  return;
                }

                const body = await bodyPromise;
                const value = {
                  cachePolicy: response.cachePolicy.toObject(),
                  url: response.url,
                  statusCode: response.fromCache ? revalidate.statusCode : response.statusCode,
                  body
                };
                let ttl = opts.strictTtl ? response.cachePolicy.timeToLive() : undefined;

                if (opts.maxTtl) {
                  ttl = ttl ? Math.min(ttl, opts.maxTtl) : opts.maxTtl;
                }

                await this.cache.set(key, value, ttl);
              } catch (error) {
                ee.emit("error", new CacheableRequest.CacheError(error));
              }
            })();
          } else if (opts.cache && revalidate) {
            (async () => {
              try {
                await this.cache.delete(key);
              } catch (error) {
                ee.emit("error", new CacheableRequest.CacheError(error));
              }
            })();
          }

          ee.emit("response", clonedResponse || response);

          if (typeof cb === "function") {
            cb(clonedResponse || response);
          }
        };

        try {
          const req = request(opts, handler);
          req.once("error", requestErrorCallback);
          req.once("abort", requestErrorCallback);
          ee.emit("request", req);
        } catch (error) {
          ee.emit("error", new CacheableRequest.RequestError(error));
        }
      };

      (async () => {
        const get = async opts => {
          await Promise.resolve();
          const cacheEntry = opts.cache ? await this.cache.get(key) : undefined;

          if (typeof cacheEntry === "undefined") {
            return makeRequest(opts);
          }

          const policy = CachePolicy.fromObject(cacheEntry.cachePolicy);

          if (policy.satisfiesWithoutRevalidation(opts) && !opts.forceRefresh) {
            const headers = policy.responseHeaders();
            const response = new Response(cacheEntry.statusCode, headers, cacheEntry.body, cacheEntry.url);
            response.cachePolicy = policy;
            response.fromCache = true;
            ee.emit("response", response);

            if (typeof cb === "function") {
              cb(response);
            }
          } else {
            revalidate = cacheEntry;
            opts.headers = policy.revalidationHeaders(opts);
            makeRequest(opts);
          }
        };

        const errorHandler = error => ee.emit("error", new CacheableRequest.CacheError(error));

        this.cache.once("error", errorHandler);
        ee.on("response", () => this.cache.removeListener("error", errorHandler));

        try {
          await get(opts);
        } catch (error) {
          if (opts.automaticFailover && !madeRequest) {
            makeRequest(opts);
          }

          ee.emit("error", new CacheableRequest.CacheError(error));
        }
      })();

      return ee;
    };
  }

}

function urlObjectToRequestOptions(url) {
  const options = { ...url
  };
  options.path = `${url.pathname || "/"}${url.search || ""}`;
  delete options.pathname;
  delete options.search;
  return options;
}

function normalizeUrlObject(url) {
  // If url was parsed by url.parse or new URL:
  // - hostname will be set
  // - host will be hostname[:port]
  // - port will be set if it was explicit in the parsed string
  // Otherwise, url was from request options:
  // - hostname or host may be set
  // - host shall not have port encoded
  return {
    protocol: url.protocol,
    auth: url.auth,
    hostname: url.hostname || url.host || "localhost",
    port: url.port,
    pathname: url.pathname,
    search: url.search
  };
}

CacheableRequest.RequestError = class extends Error {
  constructor(error) {
    super(error.message);
    this.name = "RequestError";
    Object.assign(this, error);
  }

};
CacheableRequest.CacheError = class extends Error {
  constructor(error) {
    super(error.message);
    this.name = "CacheError";
    Object.assign(this, error);
  }

};
exports = CacheableRequest;
export default exports;